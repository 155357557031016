import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: localStorage.getItem("language") || 'en',
    resources: {
        en: { common: common_en },
        fr: { common: common_fr },
    },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
