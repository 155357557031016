import '../../App.scss';
import React, { useState, useEffect, useMemo } from 'react';
import '../../css/modals.scss';
import '../../css/forms.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditTaxRateModal(props) {
    const { t } = useTranslation('common');
    const [itemToEdit, setItemToEdit] = useState(null);
    const [initialFields, setInitialFields] = useState({ isInclusive: false });

    useEffect(() => {
        if (!_.isNil(props.itemToEdit)) {
            const item = {...props.itemToEdit};
            item.rate = parseFloat(item.rate) * 100;
            setItemToEdit(item);
        } else {
            setItemToEdit(null)
        }
    }, [props.itemToEdit])

    const inclusionOptions = [
        { value: "false", label: "Exclusive" },
        { value: "true", label: "Inclusive" },
    ]

    const cleanupFields = (fields) => {
        return {
            ...fields,
            inclusive: fields.inclusive === "true",
            type: "CUSTOM",
            state: "ACTIVE",
            rate: parseFloat(fields.rate)/100
        };
    }

    const regionOptions = [
        { value: "us", label: "United States of America - All"},
        { value: "us.ca", label: "United States of America - California"},
        { value: "us.tx", label: "United States of America - Texas"},
        { value: "us.ny", label: "United States of America - New York"},
        { value: "us.fl", label: "United States of America - Florida"}
    ];

    return (
        <BaseAddOrEditItemModal
            {...props}
            initialFormFields={initialFields}
            itemToEdit={itemToEdit}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemLabel={t('taxes.tax_rate')}
            itemBaseUrl="/taxes"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="6" name="rate" label={t('taxes.tax_rate') + " %"} type="number" min="0" />
                <BaseForm.Input colSpan="6" name="inclusive" label={t('taxes.is_inclusive')} type="select" options={inclusionOptions} />
                <BaseForm.Input colSpan="6" name="region" label={t('taxes.region')} type="select" options={regionOptions} />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="text" />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditTaxRateModal;
