/*jshint sub:true*/
/* jshint -W041 */
/* jshint -W117 */


(function($) {

    var Pages = function() {
        this.pageScrollElement = 'html, body';
        this.$body = $('body');

        this.setUserOS();
        this.setUserAgent();
        this.initFormGroupDefault();
        this.initLeftNav();
    };

    /** @function setUserOS
    * @description SET User Operating System eg: mac,windows,etc
    * @returns {string} - Appends OSName to Pages.$body
    */
    Pages.prototype.setUserOS = function() {
        var OSName = "";
        if (navigator.appVersion.indexOf("Win") !== -1) OSName = "windows";
        if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "mac";
        if (navigator.appVersion.indexOf("X11") !== -1) OSName = "unix";
        if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "linux";

        this.$body.addClass(OSName);
    };

    /** @function setUserAgent
    * @description SET User Device Name to mobile | desktop
    * @returns {string} - Appends Device to Pages.$body
    */
    Pages.prototype.setUserAgent = function() {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
            this.$body.addClass('mobile');
        } else {
            this.$body.addClass('desktop');
            if (navigator.userAgent.match(/MSIE 9.0/)) {
                this.$body.addClass('ie9');
            }
        }
    };

    /** @function initFormGroupDefault
    * @description Initialize Pages form group input
    * @param {(Element|JQuery)} [context] - A DOM Element, Document, or jQuery to use as context.
    */
    Pages.prototype.initFormGroupDefault = function(context) {
        $('.form-group', context).click(function() {
            $(this).find('input').focus();
        });

        if (!this.initFormGroupDefaultRun) {
            $('body').on('focus', '.form-group :input', function() {
                $('.form-group').removeClass('focused');
                $(this).parents('.form-group').addClass('focused');
            });

            $('body').on('blur', '.form-group :input', function() {
                $(this).parents('.form-group').removeClass('focused');
//                if ($(this).val()) {
//                    $(this).closest('.form-group').find('label').addClass('fade');
//                } else {
//                    $(this).closest('.form-group').find('label').removeClass('fade');
//                }
            });

            // Only run the above code once.
            this.initFormGroupDefaultRun = true;
        }

        $('.form-group .checkbox, .form-group .radio', context).hover(function() {
            $(this).parents('.form-group').addClass('focused');
        }, function() {
            $(this).parents('.form-group').removeClass('focused');
        });
    };

    Pages.prototype.initLeftNav = function() {
//        $('body').on('mouseover', '#left-nav', function(event) {
//            $("#left-nav").addClass('show');
//        });
//        $('body').on('mouseout', '#left-nav', function(event) {
//            $("#left-nav").removeClass('show');
//        });
        $('body').on('click', '.left-nav-menu-drop', function(event) {
            if ($(this).hasClass("show")) {
                // It is showing. Hide it.
                $(this).removeClass('show');
                $(this).next().removeClass('show');
            } else {
                // It is not showing. Show it.
                $(this).addClass('show');
                $(this).next().addClass('show');
            }
        });
    };


    $.Pages = new Pages();
    $.Pages.Constructor = Pages;

 })(window.jQuery);
