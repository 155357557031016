import '../App.scss';
import 'daterangepicker/daterangepicker';
import React, { useState, useEffect, useContext } from 'react';
import { BaseContext, getPropsForMetric, currencyFormat } from '../helpers/common';
import { serverPost } from '../helpers/server';
import ContentBox from '../components/ContentBox';
import Label from '../components/Label';
import moment from 'moment';
const _ = require("lodash");

function MetricSummary(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [metricKey, setMetricKey] = useState(null);
    const [chartSummaryData, setChartSummaryData] = useState(null);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    useEffect(() => {
        setMetricKey(props.metricKey);
        setChartSummaryData(null)
    }, [props.metricKey])

    useEffect(() => {
        if (_.isNil(metricKey)) {
            return;
        }
        serverPost(getApiUrl(`/reports/metrics/summary`), { metric: metricKey }).then((res) => {
            setChartSummaryData(res);
        });
    }, [metricKey, startDate, endDate])

    const renderChange = (change) => {
        if (change >= 0) {
            return (
                <Label.Success className="body2 text-nowrap">+{ change.toFixed(2) }%</Label.Success>
            )
        } else {
            return (
                <Label.Danger className="body2 text-nowrap">{ change.toFixed(2) }%</Label.Danger>
            )
        }
    }

    if (_.isEmpty(chartSummaryData)) {
        return null;
    }

    const metricProps = getPropsForMetric(metricKey);
    const values = [];
    const changes = [];
    _.each(_.range(6), (r) => {
        if (metricProps.type === "currency") {
            values[r] = currencyFormat(chartSummaryData[r].value.value_in_cents/100);
        } else if (metricProps.type === "percent") {
            values[r] = chartSummaryData[r].value.toFixed(2)+"%";
        } else if (metricProps.type === "integer") {
            values[r] = Math.round(parseFloat(chartSummaryData[r].value));
        } else {
            values[r] = chartSummaryData[r].value.toFixed(2);
        }

        if (metricProps.type === "currency") {
            if (chartSummaryData[r].value.value_in_cents == 0) {
                changes[r] = chartSummaryData[0].value.value_in_cents > 0 ? 100: 0;
            } else {
                changes[r] = (chartSummaryData[0].value.value_in_cents/chartSummaryData[r].value.value_in_cents - 1)*100;
            }
        } else {
            if (chartSummaryData[r].value == 0) {
                changes[r] = chartSummaryData[0].value > 0 ? 100: 0;
            } else {
                changes[r] = (chartSummaryData[0].value/chartSummaryData[r].value - 1)*100;
            }
        }
    })

    const renderItem = (title, value, change) => {
        return (
            <div className="d-flex flex-column gap-2 align-items-center flex-grow-1" style={{ padding: "10px 2px" }}>
                <span className="body2 gray3 text-nowrap">{ title }</span>
                <h4 className="text-nowrap">{ value }</h4>
                { change ? renderChange(change): "" }
            </div>
        )
    }

    return (
        <ContentBox>
            <ContentBox.Body>
                <div className="d-flex flex-row gap-3 flex-wrap">
                    { renderItem("Current", values[0], null) }
                    { renderItem("1 month ago", values[1], changes[1]) }
                    { renderItem("3 months ago", values[2], changes[2]) }
                    { renderItem("6 months ago", values[3], changes[3]) }
                    { renderItem("12 months ago", values[4], changes[4]) }
                    { renderItem("24 months ago", values[5], changes[5]) }
                </div>
            </ContentBox.Body>
        </ContentBox>
    )
}

export default MetricSummary;
