import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, { useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from '../modals/BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';

function AddOrEditApiTokenModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const addApiToken = (data) => {
        serverPost(getApiUrl("/api_tokens"), data).then((res) => {
            if (res) {
                props.onTokenAdded(res);
            }
        })
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            addItem={addApiToken}
            itemLabel={t('api_tokens.api_token')}
            itemBaseUrl="/api_tokens"
        >
            <Row>
                <BaseForm.Input colSpan="12" type="text" name="name" label="Name" required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditApiTokenModal;
