import React from 'react';
import { useEffect, useContext } from "react";
import { useOutletContext, useParams, useSearchParams, useNavigate } from "react-router-dom";

function OauthCallback(props) {
    const navigate = useNavigate();
    let { key } = useParams();
    const [ searchParams ] = useSearchParams();
    let code = searchParams.get('code');
    let state = searchParams.get('state');

    useEffect(() => {
        const nonceData = localStorage.getItem(`integration_oauth_${state}`);

        let data = {};
        if (nonceData) {
            data = JSON.parse(nonceData)
        }

        const redirectUrl = `/c/${data.company_id}/integrations/${data.key}/setup/${data.integration_id}/oauth_callback${window.location.search}`
        console.log("The redirect url is " + redirectUrl);
        navigate(redirectUrl);
    }, [state]);

    return (
        <div>
            <p>Oauth callback: { code }, { state }</p>
        </div>
    );
}

export default OauthCallback;
