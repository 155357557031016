import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import React, { Fragment, useEffect, useContext, useState, createContext } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { renderChildren } from '../../helpers/common';

const ModalContext = createContext();

const BaseTailwindModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <ModalContext.Provider value={{ handleClose }}>
            <Transition.Root show={props.show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center mt-4 sm:mt-0 p-4 text-center sm:items-start sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-lg">
                                    { show && renderChildren(props) }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </ModalContext.Provider>
    );
}

const Header = (props) => {
    const { handleClose } = useContext(ModalContext);
    return (
        <div className="relative right-0 top-0 p-3 sm:block border-b">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">{props.title}</h3>
                </div>
                <div className="flex-shrink-0 flex items-center">
                    <button
                        type="button"
                        onClick={handleClose}
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-offset-2"
                    >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
}
BaseTailwindModal.Header = Header;

const Body = (props) => {
    return (
        <Modal.Body className={props.className}>
            <div className="notification-modal ahide noprint hide">
                <Alert variant="warning">
                    Warning message
                </Alert>
            </div>
            { props.children }
        </Modal.Body>
    );
}
BaseTailwindModal.Body = Body;

const Footer = (props) => {
    return (
        <div className="mt-1 flex flex-row-reverse border-t p-3">
            { props.children }
        </div>
    );
}
BaseTailwindModal.Footer = Footer;

export default BaseTailwindModal;
