import '../../../App.scss';
import React, { useContext, useEffect } from 'react';
import { BaseContext } from '../../../helpers/common';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';

function Developers() {
    const { setPageTitle } = useContext(BaseContext);

    useEffect(() => {
        setPageTitle(`Developers`);
    }, []);

    return (
        <BaseContainer>
            <ContentContainer>
                <h5>Developer Overview</h5>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Developers;
