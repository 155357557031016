import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, { useEffect, useState } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from '../modals/BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditInvoiceReminderModal(props) {
    const { t } = useTranslation('common');
    const [itemToEdit, setItemToEdit] = useState(null);

    useEffect(() => {
        if (props.reminderToEdit) {
            const item = {...props.reminderToEdit};
            item.when = item.interval_from_date > 0 ? "overdue": "due";
            item.numberOfDays = Math.abs(item.interval_from_date/86400);
            setItemToEdit(item);
        } else {
            setItemToEdit(null);
        }
    }, [props.reminderToEdit])

    const addItem = (data) => {
        data.interval_from_date = data.numberOfDays * 86400 * (data.when === "due"? -1 : 1);
        data.action_type = "notification";
        props.onAdd(data);
        props.onClose();
    }

    const updateItem = (data) => {
        data.interval_from_date = data.numberOfDays * 86400 * (data.when === "due"? -1 : 1);
        data.action_type = "notification";
        props.onUpdate(props.reminderIndex, data);
        props.onClose();
    }

    const whenOptions = [
        { value: "overdue", label: "Overdue in" },
        { value: "due", label: "Due in" }
    ];

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={t('invoices.reminder')}
            itemToEdit={itemToEdit}
            addItem={addItem}
            updateItem={updateItem}
        >
            <Row>
                <BaseForm.Input colSpan="4" type="select" name="when" label="Send email if invoice is "
                    options={whenOptions} showSearch={false} required />
                <BaseForm.Input colSpan="2" type="number" name="numberOfDays" label="Days" required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditInvoiceReminderModal;
