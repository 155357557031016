import '../App.scss';
import { getUrl, baseGetApiUrl, serverFetch, subscribeToEvent } from '../helpers/server'
import { BaseContext, hasAccessForCompany } from '../helpers/common'
import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
const _ = require("lodash");

function CompanyComponent(props) {
    let { companyId } = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState({});
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [loadingCompany, setLoadingCompany] = useState(true);

    useEffect(() => {
        const handleError = (error) => {
            if (error.status === 404) {
                navigate("/");
            } else {
                setLoadingUserInfo(false);
            }
        }

        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoadingUserInfo(false);
        } else {
        }

        fetchData();
        subscribeToEvent("settings", (data) => { fetchData(true) });
    }, []);

    const fetchData = (skipCache=false) => {
        if (companyId.startsWith("cmp_")) {
            // using id
            serverFetch(getUrl(`/companies/${companyId}`, false), { skipCache }).then((res) => {
                setCompany(res);
                setLoadingCompany(false)
            })
        } else {
            // using slug
            serverFetch(getUrl(`/companies/slug/${companyId}`, false), { skipCache }).then((res) => {
                setCompany(res);
                setLoadingCompany(false)
            })
        }
    }

    const getCompanySpecificUrl = (url) => {
        return `/c/${company.slug}${url}`;
    }

    const setPageTitle = (title) => {
        document.title = `${title} - ${company.name}`;
    }

    return (
        <BaseContext.Provider value={{
            company,
            getCompanySpecificUrl,
            isLoggedIn: !_.isEmpty(localStorage.getItem("auth_token")),
            getApiUrl: (...args) => baseGetApiUrl(company.id, ...args),
            hasAccess: (...args) => hasAccessForCompany(company.id, ...args),
            setPageTitle
        }}>
        {
            !loadingCompany && <Outlet />
        }
        </BaseContext.Provider>
    );
}

export default CompanyComponent;
