import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditAddressModal(props) {
    const { t } = useTranslation('common');

    const onAddOrUpdate = (data) => {
        props.onAdd(data);
        props.onClose();
//        const addressData = {
//            "addressLines": [
//                data.address.address_line_1,
//                data.address.address_line_2,
//                data.address.ciy,
//                data.address.state,
//                data.address.zip,
//                data.address.country
//            ]
//        }
//        serverPost("https://addressvalidation.googleapis.com/v1:validateAddress?key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY, addressData).then(res => {
//            console.log("the response is " + JSON.stringify(res));
//        })
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            addItem={onAddOrUpdate}
            itemLabel={"Address"}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="address" label={t('common.address')} type="address" required />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditAddressModal;
