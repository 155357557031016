import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';
import ConfirmationButton from './ConfirmationButton';
const _ = require('lodash');

function ArchiveButton(props) {

    return (
        <ConfirmationButton size={props.size} onConfirm={props.onDelete} variant={props.variant || "text-danger"}
            title={props.title || "Confirm Archive?"} body={props.body || "Are you sure you want to archive?"}
            confirmationText={props.confirmationText} buttonClassName={props.buttonClassName}>
            {
                !_.isEmpty(props.children) ?
                    renderChildren(props)
                : <i className="fa fa-trash"/>
            }
        </ConfirmationButton>
    );
}

export default ArchiveButton;
