import '../App.scss';
import ErrorComponent from '../components/ErrorComponent';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import React, { useEffect } from 'react';

function NotFound() {

    useEffect(() => {
        document.title = `Maple`;
    }, []);

    return (
        <BaseContainerWithoutNav>
            <ErrorComponent />
        </BaseContainerWithoutNav>
    );
}

export default NotFound;
