import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { BaseContext, getFormattedAddressForAddress } from '../helpers/common';
import { serverPatch } from '../helpers/server';
import DeleteButton from './DeleteButton';
import AddOrEditAddressModal from './modals/AddOrEditAddressModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Section from './Section';
import Notification from './Notification';
import MapleTable from './MapleTable';
const _ = require('lodash');

function TaxAddressListSection(props) {
    const { t } = useTranslation('common')
    const { getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [taxAddresses, setTaxAddresses] = useState([]);
    const [showAddOrEditAddressModal, setShowAddOrEditAddressModal] = useState(false);

    useEffect(() => {
        setSettings(props.settings);
        if (props.settings) {
            setTaxAddresses(props.settings.tax_addresses);
        } else {
            setTaxAddresses([]);
        }
    }, [props.settings])

    const onAddAddress = (data) => {
        console.log("Adding address " + JSON.stringify(data));
        const newAddresses = [...(taxAddresses || [])];
        newAddresses.push(data.address);
        setTaxAddresses(newAddresses);
        serverPatch(getApiUrl('/settings'), { tax_addresses: newAddresses }).then((res) => {
            if (res) {
                Notification.Success("Tax nexus address added successfully");
            } else {
                Notification.Danger("Unable to add tax nexus address");
            }
        })
    }

    const removeAddress = (index) => {
        console.log("Removing address " + taxAddresses[index]);
        const newAddresses = [...taxAddresses];
        newAddresses.splice(index, 1);
        setTaxAddresses(newAddresses);
        serverPatch(getApiUrl('/settings'), { tax_addresses: newAddresses }).then((res) => {
            if (res) {
                Notification.Success("Tax nexus address removed successfully");
            } else {
                Notification.Danger("Unable to remove tax nexus address");
            }
        })
    }

    if (settings.collect_taxes_type === "none") {
        return null;
    }

    return (
        <Section
            className="mt-4"
            title="Tax Nexus Addresses"
            subtitle={
                <span>When calculating sales taxes, it is important for us to know all
                your <a href="https://www.taxjar.com/sales-tax/nexus" target="_blank">nexus</a> location.
                Sales tax nexus occurs when your business has some kind of connection to a state. All states have a
                slightly different definition of nexus, but most of the time states consider that a “physical presence”
                or “economic connection” creates nexus. You only have to charge sales tax in the states in which you
                have sales tax nexus. Please refer to a tax professional to confirm your sales tax nexuses. Please
                review this periodically to ensure accuracy.</span>
            }
        >
            <MapleTable>
                <MapleTable.Content>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(taxAddresses, (row, i) =>
                            <tr key={i}>
                                <td><i className="fa fa-map-location" /> { getFormattedAddressForAddress(row) }</td>
                                <td className="text-end">
                                    <DeleteButton onDelete={() => removeAddress(i)}/>
                                </td>
                            </tr>
                        )
                    }
                    <tr>
                        <td colSpan="2" className="text-start py-3">
                            <Button variant="text-primary" size="sm" onClick={() => setShowAddOrEditAddressModal(true)}><i className="fa fa-add"/> Add New</Button>
                        </td>
                    </tr>
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            <AddOrEditAddressModal show={showAddOrEditAddressModal} onClose={setShowAddOrEditAddressModal} onAdd={onAddAddress}/>
        </Section>
    );
}

export default TaxAddressListSection;
