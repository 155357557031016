import '../App.scss';
import React from 'react';
import Header from './Header';
import SuperLeftNav from './SuperLeftNav';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

function SuperContainer(props) {
    return (
        <Container fluid className={classnames("page-wrapper", props.containerClassName)}>
            <SuperLeftNav />
            <div className="right-wrapper">
                <Header isSuper={true} />
                <div className="content-wrapper">
                    { props.children }
                </div>
            </div>
        </Container>
    );
}

export default SuperContainer;
