import '../App.scss';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
const _ = require("lodash");

function TabHeader(props) {
    const [shouldAutoMatch, setShouldAutoMatch] = useState(true);

    useEffect(() => {
        if (!_.isNil(props.shouldAutoMatch)) {
            setShouldAutoMatch(props.shouldAutoMatch);
        }
    }, [props.shouldAutoMatch]);

    const onClick = (event, item) => {
        if (item.onClick) {
            event.preventDefault();
            item.onClick(item);
        }
    }

    if (_.isEmpty(props.items) && _.isEmpty(props.rightItems)) {
        return null;
    }

    return (
        <>
        <div className="tab-header noprint">
            <div className="nav-wrapper left">
                <NavItems items={props.items} shouldAutoMatch={shouldAutoMatch} onClick={onClick}/>
            </div>
            {
                !_.isEmpty(props.rightItems) &&
                    <div className="nav-wrapper right">
                        <NavItems items={props.rightItems} shouldAutoMatch={shouldAutoMatch} onClick={onClick}/>
                    </div>
            }
        </div>
        </>
    );
}

function NavItems(props) {

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (props.shouldAutoMatch) {
            if (item.matchPathnameOnly) {
                return item.link === window.location.pathname;
            } else {
                return item.link === (window.location.pathname + window.location.search);
            }
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="nav" id="tabs">
            {
                _.map(props.items, (item, i) =>
                    item && (
                        isItemActive(item) ?
                            <div key={i} className="d-inline-block nav-link active" sister={"#tabs" + i}>
                            {
                                item.icon &&
                                    <>
                                    <i className={"fa fa-small " + item.icon}/>
                                    <span> </span>
                                    </>
                            }
                            {item.label}
                            </div>
                        : <Link key={i} className="d-inline-block nav-link" to={item.link || "/"} sister={"#tabs" + i} onClick={(event) => props.onClick(event, item)}>
                            {
                                item.icon &&
                                    <>
                                        <i className={"fa fa-small " + item.icon}/>
                                        <span> </span>
                                    </>
                            }
                            {item.label}
                            </Link>
                    )
                )
            }
            </div>
        </>
    )
}

export default TabHeader;
