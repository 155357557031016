import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { BaseContext } from '../helpers/common';
import { useNavigate } from 'react-router-dom';
import MapleTable from '../components/MapleTable';
const _ = require('lodash');

function Checklist(props) {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const navigate = useNavigate();
    const [checklistItems, setChecklistItems] = useState([]);

    useEffect(() => {
        setChecklistItems(props.checklistItems);
    }, [props.checklistItems]);

    const onCheckListItemClick = (item) => {
        navigate(getCompanySpecificUrl(item.url));
    }

    return (
        <MapleTable>
            <MapleTable.Content>
                <tbody className="divide-y divide-gray-200">
                {
                    _.map(checklistItems, (item, i) =>
                        <tr key={i} className="cursor-pointer" onClick={() => onCheckListItemClick(item)}>
                            <td width="30px">
                            {
                                item.is_complete ?
                                    <div className="flex items-center"><i className="text-lg fa fa-check-circle fa-primary"/></div>
                                : <div className="flex items-center"><i className="text-lg fa fa-circle-o"/></div>
                            }
                            </td>
                            <td className="!pl-1">{ item.message }</td>
                        </tr>
                    )
                }
                </tbody>
            </MapleTable.Content>
        </MapleTable>
    );
}

export default Checklist;
