import '../../App.scss';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import '../../css/modals.scss';
import '../../css/forms.scss';
import BaseForm from '../BaseForm';
import { BaseContext, getCurrencyOptions, renderDescriptionForItemPricing } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomerInput from "../CustomerInput";
const _ = require('lodash');

function AddOrEditPromoCodeModal(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [itemToEdit, setItemToEdit] = useState(null);
    const [initialFields, setInitialFields] = useState({ isInclusive: false });
    const [hasCustomerRestriction, setHasCustomerRestriction] = useState(false);

    useEffect(() => {
        if (!_.isNil(props.itemToEdit)) {
            const item = {...props.itemToEdit};
            setHasCustomerRestriction(!_.isNil(item.customer));
            setItemToEdit(item);
        } else {
            setHasCustomerRestriction(false);
        }
    }, [props.itemToEdit])

    useEffect(() => {
        if (!props.show) {
            setHasCustomerRestriction(false);
            setItemToEdit(null);
        }
    }, [props.show])

    const cleanupFields = (fields) => {
        const cleanedFields = {...fields};
        if (fields.has_customer_restriction) {
            cleanedFields.customer_id = fields.customer.id;
        } else {
            cleanedFields.customer_id = null;
        }
        return cleanedFields;
    }

    const onFieldChange = (name, value) => {
        if (name === "has_customer_restriction") {
            setHasCustomerRestriction(value);
        }
    }

    const couponId = props.coupon ? props.coupon.id: null;
    return (
        <BaseAddOrEditItemModal
            {...props}
            initialFormFields={initialFields}
            itemToEdit={itemToEdit}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            onFieldChange={onFieldChange}
            itemLabel={t('promo_codes.promo_code')}
            itemBaseUrl={`/coupons/${couponId}/promo`}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="code" label={t('promo_codes.code')} type="text" required />
            </Row>
            <br/>
            <Row className="hide">
                <BaseForm.Input colSpan="8" name="has_customer_restriction" label={t('promo_codes.has_customer_restrictions')} type="switch" />
                {
                    hasCustomerRestriction &&
                        <Row>
                            <CustomerInput colSpan="12" required />
                        </Row>
                }
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditPromoCodeModal;
