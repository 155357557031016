import '../../../App.scss';
import React from 'react';
import { serverPost } from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import { useEffect, useContext } from "react";
import { useOutletContext, useParams } from "react-router-dom";

function Done(props) {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    let { id } = useParams();
    const { setShowPrev, setDonePath } = useOutletContext();
    const { integration } = useContext(IntegrationContext);

    useEffect(() => {
        setPageTitle(`Integration Setup - Done`);
    }, []);

    useEffect(() => {
        setShowPrev(false);
        setDonePath(getCompanySpecificUrl("/integrations"))
    }, []);

    useEffect(() => {
        serverPost(getApiUrl(`/integrations/${id}/finish_setup`)).then((res) => {

        })
    }, [])

    return (
        <div className="d-flex flex-column gap-3 align-items-center">
            <div className="d-flex flex-row gap-3 align-items-center">
                <div>
                    <img src={ integration.icon } alt="Integration Logo" className="integration-logo" />
                </div>
                <h2>{ integration.name }</h2>
            </div>
            <div className="integration-flow-content">
                <h2>Successfully Installed</h2>
            </div>
            <br/>
        </div>
    );
}

export default Done;
