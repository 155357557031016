import '../App.scss';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { getUrl, serverPost } from '../helpers/server';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import LoginComponent from '../components/LoginComponent';
import { Row, Col, Alert, Container } from 'react-bootstrap';

function VerifyEmail() {
    const [ searchParams ] = useSearchParams();
    let code = searchParams.get('code');
    const [invalidCode, setInvalidCode] = useState(false);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if (code) {
            serverPost(getUrl(`/users/verify`), { code: code }).then((res) => {
                if (res) {
                    setVerified(true);
                } else {
                    setInvalidCode(true);
                }
            });
        } else {
            setInvalidCode(true);
        }
    }, [code]);

    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                    <Col md={{span: 6, offset:3}}>
                        <h3>Verify Email</h3>
                        <br/>
                        {
                            !verified && invalidCode &&
                                <>
                                <Alert variant="danger">Verification code not present or is invalid.</Alert>
                                <p>Please click on the link provided in your email or have the company admin resend the invitation email.</p>
                                </>
                        }
                        {
                            verified &&
                                <>
                                    <Alert variant="success">Successfully verified your email. Login to continue</Alert>
                                    <Row>
                                        <Col md="12">
                                            <LoginComponent />
                                        </Col>
                                    </Row>

                                </>
                        }
                    </Col>
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default VerifyEmail;
