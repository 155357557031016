import '../App.scss';
import React from 'react';
import BaseLeftNav from '../components/BaseLeftNav';

function SuperLeftNav(props) {
    const items = [
        { label: "Dashboard", link: "/internal/super/dashboard", icon: "dashboard-icon" },
        { label: "Tasks", link: "/internal/super/tasks", icon: "dashboard-icon" },
        { label: "Playground", link: "/internal/playground", icon: "dashboard-icon" },
    ];

    return (
        <BaseLeftNav items={items} {...props} />
    );
}

export default SuperLeftNav;
