import '../App.scss';
import React, { useEffect, useContext } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/common";

function RequireSuperUser(props) {
    const { isSuperUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSuperUser) {
            navigate('/');
        }
    }, [isSuperUser, navigate]);

    return (
        <>
        {
            isSuperUser && <Outlet />
        }
        </>
    );
}

export default RequireSuperUser;
