import '../../App.scss';
import variables from '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { serverPost } from '../../helpers/server';
import { getLabelForAction, BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import BaseForm from '../../components/BaseForm';
import { Table } from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
const _ = require('lodash');

function Activity() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" });
    const [sort, setSort] = useState("createdAtDesc");
    const [query, setQuery] = useState(null)

    useEffect(() => {
        setPageTitle(`Activity`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
            }
        }
        serverPost(getApiUrl("/logs/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setEvents(results);
                } else {
                    setEvents(_.concat(events, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "query") {
            setQuery(value);
        }
    }

    const getIndicatorForLog = (reference_type) => {
        const refMap = {
            "invoice": variables.navyColor,
            "subscription": variables.aegeanColor,
            "customer": variables.indigoColor,
            "credit": variables.tigerColor,
            "discount": variables.primaryLightColor,
            "bundle_pricing": variables.primaryDarkColor,
            "product_pricing": variables.warningLightColor,
            "item_pricing": variables.positiveLightColor,
            "contract": variables.redLightColor,
        }
        const color = _.has(refMap, reference_type) ? refMap[reference_type]: variables.gray4Color;
        return (
            <div className="indicator" style={{ background: color }}/>
        )
    }

    const getReferenceUrl = (event) => {
        const refMap = {
            "invoice": `/invoice/${event.reference_id}`,
            "subscription": `/subscription/${event.reference_id}`,
            "customer": `/customer/${event.reference_id}`,
            "credit": `/credit/${event.reference_id}`,
            "discount": `/discount/${event.reference_id}`,
            "bundle_pricing": `/bundle_pricing/${event.reference_id}`,
            "product_pricing": `/product_pricing/${event.reference_id}`,
            "item_pricing": `/item_pricing/${event.reference_id}`,
            "contract": `/contract/${event.reference_id}`,
        }
        if (_.has(refMap, event.reference_type)) {
            return getCompanySpecificUrl(refMap[event.reference_type]);
        } else {
            return null;
        }
    }

    const sortOptions = [
        { value: "createdAtAsc", label: 'Created At, Asc' },
        { value: "createdAtDesc", label: 'Created At, Desc' },
    ]

    const renderDescriptionForEvent = (event) => {
        if (event.reference_type === "contract") {
            return <Link to={ getReferenceUrl(event) }><span>{ event.reference.title }</span></Link>
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={events.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Events">
                        <MapleTable>
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <MapleTable.Header>
                                    <MapleTable.LeftActions>
                                        <BaseForm.TextGroup name="query" icon="search-icon" placeholder="Search Contracts" />
                                    </MapleTable.LeftActions>
                                    <MapleTable.RightActions>
                                        <div className="body2 gray4">Sort By: </div>
                                        <BaseForm.Input type="select" name="sort" options={sortOptions} />
                                    </MapleTable.RightActions>
                                </MapleTable.Header>
                            </BaseForm>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th></th>
                                        <th>Event Time</th>
                                        <th>Performed By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(events, (row, i) =>
                                         <tr key={i}>
                                            <td className="no-stretch">{ getIndicatorForLog(row.reference_type) }</td>
                                            <td>{ getLabelForAction(row.action) }</td>
                                            <td>{ renderDescriptionForEvent(row) }</td>
                                            <td>{ moment(row.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                            <td>{ row.action_performed_by_user && (row.action_performed_by_user.name || row.action_performed_by_user.email) }</td>
                                         </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                        <tr>
                                            <td colSpan="15" className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Activity;
