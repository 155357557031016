import '../App.scss';
import React, { useEffect, useState } from 'react';
const _ = require("lodash");

function EditorVariablesModal(props) {
    const [variableGroups, setVariableGroups] = useState({});

    useEffect(() => {
        setVariableGroups(_.groupBy(props.variables, "group"));
    }, [props.variables])

    const onClick = (event, key) => {
        if (event) {
            event.stopPropagation();
        }
        if (props.onVariableSelect) {
            props.onVariableSelect(key);
        }
    }

    const onWrapperClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <div className="variables-modal-wrapper" onClick={onWrapperClick}>
            <div className="variables-modal" onClick={onClick} style={{ }}>
            {
                _.map(variableGroups, (variables, groupName) =>
                    <div className="variable-item-group" key={groupName}>
                        <h4>{ groupName }</h4>
                        <div className="variable-items">
                        {
                            _.map(variables, (v, i) =>
                                <span key={i} className="variable-item" onClick={(event) => onClick(event, v.key)}>{ v.name }</span>
                            )
                        }
                        </div>
                    </div>
                )
            }
            </div>
        </div>
    );
}

export default EditorVariablesModal;
